<template>
  <b-card no-body>
    <b-card-body>
      <b-row>

        <b-col
          v-if="$can('audit', 'all')"
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Branch') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="branchFilter"
            :options="branchOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:branchFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Subject') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="subjectFilter"
            :options="subjectOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:subjectFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Year') }}</label>
          <b-form-input
            type="number"
            :value="yearFilter"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:yearFilter', val)"
          />
        </b-col>
        <!--
        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <feather-icon
            icon="FilterIcon"
            size="16"
          />&nbsp;
          <label>{{ $t('Year') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="yearFilter"
            :options="yearOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:yearFilter', val)"
          />
        </b-col> -->

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    branchFilter: {
      type: [String, null],
      default: null,
    },
    branchOptions: {
      type: Array,
      required: true,
    },
    subjectFilter: {
      type: [String, null],
      default: null,
    },
    subjectOptions: {
      type: Array,
      required: true,
    },
    yearFilter: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      yearValue: this.yearFilter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
